
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import moment from 'moment';
import { ElNotification } from 'element-plus';

import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-entity-course',
  components: {
    Datatable,
  },
  data() {
    return {
      moment: '' as any,
      entity_id: '' as any,
      tranche_id: '' as any,
      institute_id: '' as any,
      bank_id: '' as any,
      agent_bank__id: '' as any,
      mobile_bank_id: '' as any,
      account_type: '' as any,
      institute_name: '' as any,
      entity_name: '' as any,
      ids: [] as any,
      course_ids: [] as any,
      batch_ids: [] as any,
      showdetails: false,
      traineeList: [] as any,
      entity_id_account: '' as any,
      load: false,
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: false,
        },
        {
          name: 'Institute',
          key: 'institute',
          width: '200px',
          sortable: false,
        },
        {
          name: 'Training Partner',
          key: 'entity',
          sortable: false,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: false,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: false,
        },
        {
          name: 'Start',
          key: 'start',
          sortable: false,
        },
        {
          name: 'End',
          key: 'end',
          sortable: false,
        },
        {
          name: 'Trainee',
          key: 'trainee',
          sortable: false,
        },
        {
          name: 'Reference Number',
          key: 'ref_num',
          sortable: false,
        },
        {
          name: 'Registration Number',
          key: 'registration_number',
          sortable: false,
        },
        {
          name: 'Account type',
          key: 'account_type',
          sortable: false,
        },
        {
          name: 'Account Number',
          key: 'acc_number',
          sortable: false,
        },
        {
          name: 'Father',
          key: 'father_name',
          sortable: false,
        },
        {
          name: 'Mother',
          key: 'Mother_name',
          sortable: false,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: false,
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: false,
        },
        {
          name: 'Permanent Address',
          width: '180px',
          key: 'per_add',
          sortable: false,
        },
        {
          name: 'Postcode',
          key: 'post_code',
          sortable: false,
        },
        {
          name: 'Upazilla',
          key: 'upaila',
          sortable: false,
        },
        {
          name: 'Home District',
          key: 'home_dist',
          sortable: false,
        },
        {
          name: 'Attendance Percentage',
          key: 'att_percentage',
          sortable: false,
        },
        {
          name: 'Ethnic',
          key: 'ethnic',
          sortable: false,
        },
        {
          name: 'Physically Disabled',
          key: 'physically_disable',
          sortable: false,
        },
        {
          name: 'Income Certificate',
          key: 'income_cer',
          sortable: false,
        },
        {
          name: 'Disbursement Status',
          key: 'disb_status',
          sortable: false,
        },
        {
          name: 'Disbursed Amount',
          key: 'disb_amount',
          sortable: false,
        },
        {
          name: 'Disbursement Date',
          key: 'disb_date',
          sortable: false,
        },
        {
          name: 'Remarks',
          key: 'remarks',
          sortable: false,
        },
      ],
      json_fields: {
        'Complete name': 'name',
        City: 'city',
        Telephone: 'phone.mobile',
        'Telephone 2': {
          field: 'phone.landline',
          callback: (value) => {
            return `Landline Phone - ${value}`;
          },
        },
      },
      json_data: [
        {
          name: 'Tony Peña',
          city: 'New York',
          country: 'United States',
          birthdate: '1978-03-15',
          phone: {
            mobile: '1-541-754-3010',
            landline: '(541) 754-3010',
          },
        },
        {
          name: 'Thessaloniki',
          city: 'Athens',
          country: 'Greece',
          birthdate: '1987-11-23',
          phone: {
            mobile: '+1 855 275 5071',
            landline: '(2741) 2621-244',
          },
        },
      ],
      start_date: '' as any,
      end_date: '' as any,
    };
  },
  async created() {
    this.moment = moment;
    this.load = true;
    // this.entity_id = this.$route.params.entity_id;
    // this.tranche_id = this.$route.params.tranche_id;
    // this.bank_id = this.$route.params.bank_id;
    // this.institute_id = this.$route.params.institute_id;
    // this.institute_name = this.$route.params.institute_name;
    // this.mobile_bank_id = this.$route.params.mobile_bank_id;

    let data = window.location.search;

    let urlParams = new URLSearchParams(data);
    this.entity_id = urlParams.get('entity_id');
    this.bank_id = urlParams.get('bank_id');
    this.mobile_bank_id = urlParams.get('mobile_bank_id');
    this.agent_bank__id = urlParams.get('agent_bank__id');
    this.tranche_id = urlParams.get('tranche_id');
    this.institute_id = urlParams.get('institute_id');
    this.start_date = urlParams.get('start_date');
    this.end_date = urlParams.get('end_date');
    this.entity_name = urlParams.get('entity_name');
    this.entity_id_account = urlParams.get('entity_id_account');
    this.institute_name = urlParams.get('institute_name');
    this.account_type = urlParams.get('account_type');
    console.log('1st time account type' + this.account_type);
    this.account_type == null
      ? (this.account_type = 'entitycall')
      : this.account_type;
    console.log('account type check false e dukar kotha' + this.account_type);
    await this.getTraineeList();
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let data = new FormData();
      if (this.entity_id && this.account_type == 'entitycall') {
        data.set('entity_id', this.entity_id);
        data.set('tranche_id', this.tranche_id);
        data.set('start_date', this.start_date);
        data.set('end_date', this.end_date);
      }
      if (this.institute_id) {
        data.set('tranche_id', this.tranche_id);
        data.set('institute_id', this.institute_id);
        data.set('start_date', this.start_date);
        data.set('end_date', this.end_date);
      }

      if (this.bank_id || this.account_type == 'bank') {
        data.set('account_type', this.account_type);
        data.set('bank_id', this.bank_id);
        data.set('entity_account_id', this.entity_id);
        data.set('tranche_id', this.tranche_id);
        data.set('start_date', this.start_date);
        data.set('end_date', this.end_date);
      }

      if (this.agent_bank__id || this.account_type == 'agent') {
        data.set('account_type', this.account_type);
        data.set('agent_bank__id', this.agent_bank__id);
        data.set('entity_account_id', this.entity_id);
        data.set('tranche_id', this.tranche_id);
        data.set('start_date', this.start_date);
        data.set('end_date', this.end_date);
      }
      if (this.agent_bank__id || this.account_type == 'agent') {
        data.set('account_type', this.account_type);
        data.set('agent_bank__id', this.agent_bank__id);
        data.set('entity_account_id', this.entity_id);
        data.set('tranche_id', this.tranche_id);
        data.set('start_date', this.start_date);
        data.set('end_date', this.end_date);
      }

      if (this.mobile_bank_id || this.account_type == 'mobile') {
        data.set('account_type', this.account_type);
        data.set('mobile_bank_id', this.mobile_bank_id);
        data.set('entity_account_id', this.entity_id);
        data.set('tranche_id', this.tranche_id);
        data.set('start_date', this.start_date);
        data.set('end_date', this.end_date);
      }
      axios
        .post(`${this.VUE_APP_API_URL}/api/report/stipendlistexport`, data, {
          responseType: 'blob',
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'StipendList.xlsx');
          document.body.appendChild(link);
          link.click();
          ElNotification.closeAll();
        })
        .catch((response) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async getTraineeList() {
      console.log(this.account_type);
      if (this.entity_id && this.account_type == 'entitycall') {
        console.log('entity_api call');
        console.log('entity_api account type' + this.account_type);
        ApiService.get(
          this.VUE_APP_STIPEND_TRAINEE_SELECTED_API +
            '?tranche_id=' +
            this.tranche_id +
            '&entity_id=' +
            this.entity_id +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date
        )
          .then((response) => {
            this.traineeList = response.data.data;
            this.showdetails = true;
            this.load = false;
            console.log(response);
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      }
      if (this.institute_id) {
        console.log('institute_api call');
        ApiService.get(
          this.VUE_APP_STIPEND_TRAINEE_SELECTED_API +
            '?tranche_id=' +
            this.tranche_id +
            '&institute_id=' +
            this.institute_id +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date
        )
          .then((response) => {
            this.traineeList = response.data.data;
            this.showdetails = true;
            this.load = false;
            console.log(response);
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      }
      if (this.bank_id || this.account_type == 'bank') {
        console.log('bank_api call' + this.account_type);
        ApiService.get(
          this.VUE_APP_STIPEND_TRAINEE_SELECTED_API +
            '?entity_account_id=' +
            this.entity_id +
            '&tranche_id=' +
            this.tranche_id +
            '&bank_id=' +
            this.bank_id +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date +
            '&account_type=' +
            this.account_type
        )
          .then((response) => {
            this.traineeList = response.data.data;
            this.showdetails = true;
            console.log(response);
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
            this.load = false;
          });
      }
      if (this.agent_bank__id || this.account_type == 'agent') {
        console.log('agent_api call');
        ApiService.get(
          this.VUE_APP_STIPEND_TRAINEE_SELECTED_API +
            '?entity_account_id=' +
            this.entity_id +
            '&tranche_id=' +
            this.tranche_id +
            '&agent_bank__id=' +
            this.agent_bank__id +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date +
            '&account_type=' +
            this.account_type
        )
          .then((response) => {
            this.traineeList = response.data.data;
            this.showdetails = true;
            console.log(response);
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
            this.load = false;
          });
      }
      if (this.mobile_bank_id || this.account_type == 'mobile') {
        console.log('mobile_bank api call');
        ApiService.get(
          this.VUE_APP_STIPEND_TRAINEE_SELECTED_API +
            '?entity_account_id=' +
            this.entity_id +
            '&tranche_id=' +
            this.tranche_id +
            '&mobile_bank_id=' +
            this.mobile_bank_id +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date +
            '&account_type=' +
            this.account_type
        )
          .then((response) => {
            this.traineeList = response.data.data;
            this.showdetails = true;
            console.log(response);
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
            this.load = false;
          });
      }
    },
  },
  setup() {},
});
